<template>
  <div id="inventoryReservationForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="inventoryReservation.processWorksheetId"
        label="Worksheet ID"
        :required="false"
      />
      <v-text-field
        v-model="inventoryReservation.inventoryId"
        label="Inventory ID"
        :required="false"
      />
      <v-text-field
        v-model="inventoryReservation.inventoryUnitId"
        label="Inventory Unit ID"
        :required="false"
      />
      <v-text-field
        v-model="inventoryReservation.quantity"
        label="Quantity"
        :required="false"
      />

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getInventoryReservation } from "@/services/inventoryReservation";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      inventoryReservation: {
        relations: {},
        processWorksheetId: "",
        inventoryId: "",
        inventoryUnitId: "",
        quantity: "",
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("inventoryReservation", [
      "createInventoryReservation",
      "updateInventoryReservation",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let inventoryReservation = this.inventoryReservation;
        delete inventoryReservation.relations;

        this.updateInventoryReservation(inventoryReservation)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage(
              "InventoryReservation was updated successfully"
            );
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating InventoryReservation, please try again later"
            );
          });
      } else {
        let inventoryReservation = this.inventoryReservation;
        delete inventoryReservation.relations;

        this.createInventoryReservation(inventoryReservation)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage(
              "InventoryReservation was created successfully"
            );
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating InventoryReservation, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.inventoryReservation.id = this.id;
        getInventoryReservation(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.inventoryReservation,
                property
              )
            ) {
              this.inventoryReservation[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
